<template>
  <div class="header">
    <div class="header__container">

      <burger-menu v-if="showBurgerMenu" class="header__item-menu"/>
      <img v-else class="header__item-back" @click="$router.go(-1)" src="@/assets/img/header/icon-back.svg" alt="back">

      <div class="header__logo">
        <img @click="clickToLogo" class="header__item-logo" src="@/assets/img/header/logo-two.svg" alt="logo"/>
      </div>

      <div class="header__item-btn" @click="$router.push('/login')">Войти</div>

      <div class="header__login-btn" @click="$router.push('/login')">
        <img src="@/assets/img/header/icon-login.svg" alt="">
        <div>ВОЙТИ</div>
      </div>

    </div>
    <el-divider/>
  </div>

</template>

<script>
import BurgerMenu from "./components/BurgerMenu";

export default {
  name: "AppHeader",
  components: {
    BurgerMenu
  },
  data() {
    return {
      menuActive: false
    }
  },
  computed:{
    showBurgerMenu(){
      return this.$route.path ==='/' || this.$route.path ==='/payment/finish' || this.$route.path === '/payment/error'
    }
  },
  methods:{
    clickToLogo() {
      if(this.$route.path !=='/')
        this.$router.push('/')
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  position: fixed;
  z-index: 3000;
  background-color: #FFFFFF;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;

  //z-index: 2;

  width: 100%;

  &__item-back {
    margin-right: 24px;
    cursor: pointer;
  }

  &__login-btn {
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 32px;
    height: 32px;
    cursor: pointer;

    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 105%;

    text-align: center;
    color: #1454F2;

    transition: .3s;

    img {
      margin-bottom: 6px;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 1170px;
    height: 74px;
    transition: 0.2s;

    margin-bottom: 3px;
  }

  &__item-img {
    margin-right: 36px;
    cursor: pointer;
  }

  &__item-title:nth-child(2n) {
    margin-right: 63px;
  }

  &__item-title {
    font-family: Raleway, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;

    cursor: pointer;
    transition: 0.2s;
  }

  &__logo {
    padding-left: 60px;
  }

  &__item-logo {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 30px;

    margin-top: 4px;
    cursor: pointer;
  }

  &__item-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;


    width: 110px;
    height: 42px;

    background: #FFFFFF;
    border: 2px solid #1454F2;
    box-sizing: border-box;
    border-radius: 16px;

    cursor: pointer;
  }
}
</style>

<style lang="scss">
.header {
  .el-divider {
    margin: 0;
  }
}
@media screen and (max-width: 1200px) {
  .header__container {
    width: 980px !important;
    transition: 0.2s;
  }
  .header__item-btn {
    margin-right: 24px !important;
    transition: 0.2s;
  }
  .header__item-back{
    margin-left: 24px !important;
    transition: 0.2s;
  }
}
@media screen and (max-width: 980px) {
  .header__container {
    width: 100% !important;
    height: 64px !important;
    transition: 0.2s;
  }
  .header__container {
    width: 100% !important;
    height: 64px !important;
    transition: 0.2s;
  }
  .header__item-logo {
    height: 25px !important;
    transition: 0.2s;
  }
}
@media screen and (max-width: 600px) {
  .header__container {
    height: 54px !important;
    transition: 0.2s;
  }
  .header__item-btn {
    display: none !important;
  }
  .header__login-btn {
    display: flex !important;
    margin-right: 24px;
  }
  .header__logo {
    padding-left: 0 !important;
  }
  .header__item-logo {
    height: 20px !important;
    margin-left: -15px !important;

    transition: 0.2s;
  }
  .header__item-menu {
    margin-left: 24px;
  }
}
</style>
