<template>
  <div class="course-info-header">
    <div class="course-info-header__container">

      <img class="course-info-header__logo"
           @click="$router.push('/')"
           src="@/assets/img/header/logo-two.svg"
           alt="AYA school">

      <nav class="course-info-header__menu">
        <div class="course-info-header__menu-link" @click="scrollTo('#description')">Что входит</div>
        <div class="course-info-header__menu-link" @click="scrollTo('#programInfo')">Программа</div>
<!--        <div class="course-info-header__menu-link" @click="scrollTo('#author')">Автор</div>-->
<!--        <div class="course-info-header__menu-link" @click="scrollTo('#reviews')">Отзывы</div>-->
        <div class="course-info-header__menu-link" @click="$router.push('/courses')">Другие курсы</div>
      </nav>

      <div class="course-info-header__login-btn" @click="$router.push('/login')">Войти</div>

      <div class="course-info-header__login-mobile-btn" @click="$router.push('/login')">
        <img src="@/assets/img/header/icon-login.svg" alt="">
        <div>ВОЙТИ</div>
      </div>

        <nav class="course-info-header__nav">
          <div class="course-info-header__menu-link" @click="scrollTo('#description')">Что входит</div>
          <div class="course-info-header__menu-link" @click="scrollTo('#programInfo')">Программа</div>
<!--          <div class="course-info-header__menu-link" @click="scrollTo('#author')">Автор</div>-->
<!--          <div class="course-info-header__menu-link" @click="scrollTo('#reviews')">Отзывы</div>-->
          <div class="course-info-header__menu-link" @click="$router.push('/courses')">Другие курсы</div>
        </nav>


    </div>
  </div>
</template>

<script>
export default {
  name: "CourseInfoHeader",
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth
    },
    offsetScroll() {
      return this.windowWidth > 670 ? -100 : -140
    }
  },
  methods:{
    scrollTo(id){
      this.$scrollTo(id, {offset: this.offsetScroll})
    }
  }
}
</script>

<style lang="scss" scoped>
.course-info-header {
  position: fixed;
  z-index: 3;
  width: 100%;

  background-color: #FFFFFF;
  box-shadow: 1px -10px 20px rgba(155, 154, 154, 0.51);
  transition: 0.2s;

  &__container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 1170px;
    height: 90px;
    margin: 0 auto;

    box-sizing: border-box;

    transition: 0.2s;

    &:after {
      display: none;
      position: absolute;
      content: '';

      left: 30px;
      bottom: 0;

      width: calc(100% - 60px);
      height: 0;

      border-bottom: 1px solid #AEB3B9;
    }
  }

  &__logo {
    width: 158px;
    transition: 0.2s;
    cursor: pointer;
  }

  &__menu {
    display: flex;
  }
  &__nav {
    display: none;
    align-items: center;
    justify-content: space-between;

    flex-basis: 100%;
    margin-top: 32px;
  }

  &__menu-link {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 90%;
    color: #222222;

    margin-right: 44px;

    cursor: pointer;

    &:last-child {
      margin-right: 0 !important;
    }
  }

  &__login-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    color: #222222;

    width: 110px;
    height: 42px;

    margin-left: 40px;

    background: #FFFFFF;
    border: 2px solid #1454F2;
    box-sizing: border-box;
    border-radius: 16px;

    cursor: pointer;
  }

  &__login-mobile-btn {
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 32px;
    height: 32px;
    cursor: pointer;

    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 105%;

    text-align: center;
    color: #1454F2;

    transition: .3s;

    img {
      margin-bottom: 6px;
    }
  }


  @media screen and (max-width: 1200px) {
    &__container {
      width: 980px;
    }
  }

  @media screen and (max-width: 980px) {
    &__container {
      width: 100%;
      padding: 0 30px;
      height: 80px;
    }
    &__logo {
      width: 140px;
      transition: 0.2s;
    }
    &__menu-link {
      margin-right: 34px;
    }
  }

  @media screen and (max-width: 880px) {
    .user-header__item-logo {
      height: 20px !important;
      transition: 0.2s;
    }
    &__login-btn {
      margin-left: 0;
    }
    &__menu-link {
      font-size: 14px;
      margin-right: 28px;
    }
  }

  @media screen and (max-width: 768px) {
    &__login-btn {
      display: none;
    }
    &__login-mobile-btn  {
      display: flex;
    }
    &__logo {
      width: 120px;
      transition: 0.2s;
    }
  }

  @media screen and (max-width: 670px) {
    &__container {
      flex-wrap: wrap;
      padding: 20px 30px;
      height: auto;
      &:after {
        display: block;
      }
    }
    &__menu {
      display: none;
    }
    &__menu-link {
      margin-right: 0;
      font-weight: 500;
    }
    &__nav {
      display: flex;
    }
    &__login-btn {
      display: flex;
    }
    &__login-mobile-btn  {
      display: none;
    }
  }
  @media screen and (max-width: 560px) {
    box-shadow: 1px -10px 20px rgb(155 154 154 / 20%);
  }
  @media screen and (max-width: 480px) {
    box-shadow: none;
    &__container {
      height: 80px;
      &:after {
        display: none;
      }
    }
    &__nav {
      display: none;
    }
    &__login-btn {
      display: none;
    }
    &__login-mobile-btn {
      display: flex;
    }
  }

}

</style>