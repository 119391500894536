<template>
  <div class="categories-wrapper">
    <div class="categories-wrapper__categories">
      <div class="categories-wrapper__category"
           :class="{'active': item.id === selectedCategoryId && $route.name === 'courses'}"
           v-for="(item,index) in categories" :key="index"
           @click="handleClickCategory(item)">
        <div class="categories-wrapper__category-name">{{item.name}}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CategoriesForMenu",
  computed: {
    categories() {
      return this.$store.state.courses.categories
    },
    selectedCategoryId() {
      return this.$store.state.courses.selectedCategoryId
    },
  },
  methods: {
    handleClickCategory(category) {
      this.$store.commit('courses.setSelectedCategory', category.id)
      if(this.$route.name !== 'courses')
      this.$router.push('/courses')
    }
  }
}
</script>

<style lang="scss" scoped>
.categories-wrapper{
  display: flex;
  flex-direction: column;

  &__categories{
    display: flex;
    flex-wrap: wrap;
  }
  &__category{
    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 300px;

    padding-left: 32px;
    padding-right: 32px;
    margin-bottom: 24px;

    background: #FFFFFF;

    height: 54px;
    margin-right: 28px;

    border: 2px solid #1454F2;
    box-sizing: border-box;
    border-radius: 120px;

    cursor: pointer;

    transition: all .1s ease-in;

    &.active, &:hover {
      background-color: #1454F2;
      > div {
        color: #FFFFFF!important;
      }
    }
  }
  &__category-name{
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;

    color: #121212;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media screen and (max-width: 980px) {
  .categories-wrapper {
    padding:  0px 24px;
    transition: 0.2s;
    margin-bottom: 46px;
    &__title{
      font-size: 36px !important;
      transition: 0.2s;
    }
  }
}

@media screen and (max-width: 600px) {
  .categories-wrapper__category{
    max-width: 250px;
  }
  .categories-wrapper {
    transition: 0.2s;
    transition: 0.2s;
    margin-bottom: 26px;
    &__title{
      font-size: 28px !important;
      line-height: 42px !important;

      transition: 0.2s;
    }
  }
}


@media screen and (max-width: 340px) {
  .categories-wrapper {
    transition: 0.2s;
    margin-bottom: 0px;
    &__title{
      font-size: 20px !important;
      line-height: 32px!important;

      transition: 0.2s;
    }
  }
}
</style>