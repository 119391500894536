<template>
  <div class="user-header">
    <div class="user-header__container">
      <div class="user-header__item">


        <img v-if="$route.path!=='/'" class="user-header__item-img" @click="$router.go(-1)" src="@/assets/img/header/icon-back.svg" alt="back">
        <burger-menu v-else class="user-header__item-menu"/>

        <router-link to="/my-courses" class="user-header__item-title">Мои курсы</router-link>
        <router-link to="/courses" class="user-header__item-title">Все курсы</router-link>
      </div>
      <div class="user-header__logo">
        <img @click="$router.push('/')" class="user-header__item-logo" src="@/assets/img/header/logo-two.svg" alt="logo"/>
      </div>
      <div>
        <el-dropdown trigger="click">
          <avatar/>
          <el-dropdown-menu class="header-account-menu" slot="dropdown">
            <el-dropdown-item>
              <account-menu/>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-divider/>
  </div>
</template>

<script>
import AccountMenu from "./components/AccountMenu";
import Avatar from "./components/Avatar";
import BurgerMenu from "../appHeader/components/BurgerMenu";

export default {
  name: "UserHeader",
  components: {
    AccountMenu,
    Avatar,
    BurgerMenu
  },
}
</script>

<style scoped lang="scss">
.user-header {
  position: fixed;

  background-color: #FFFFFF;
  z-index: 3;

  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 25px;

  width: 100%;
  //height: 73px;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 1170px;
    height: 74px;
    margin-bottom: 3px;

    transition: 0.2s;

    .logout__btn{
      display: none !important;
    }
  }

  &__item {
    display: flex;
    align-items: center;

    transition: 0.2s;
  }

  &__item-img {
    margin-right: 35px;
    cursor: pointer;
  }
  &__item-menu{
    margin-right: 28px;
  }

  &__item-title:nth-child(2n) {
    margin-right: 63px;
  }

  &__item-title {
    font-family: Raleway, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    text-decoration: none;

    color: #000000;

    cursor: pointer;
    transition: 0.2s;
  }

  &__logo {
    padding-right: 230px;
  }
  &__item-logo {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 30px;
    cursor: pointer;
  }

}
</style>

<style lang="scss">
.user-header {
  .el-divider {
    margin: 0;
  }
}

.el-dropdown-menu.header-account-menu {
  top: 55px !important;

  width: 240px;
  background: #F7F7F7;
  border-radius: 10px;

  .popper__arrow {
    display: none;
  }

  .el-dropdown-menu__item {
    background: #F7F7F7;
    cursor: default;
  }

}

@media screen and (max-width: 1200px) {
  .user-header__container {
    width: 980px !important;
    transition: 0.2s;
  }
  .user-header__logo{
    padding-right: 260px !important;
  }
  .user-header__item{
    margin-right: 25px;
  }
}

@media screen and (max-width: 980px) {
  .user-header__container {
    width: 100% !important;
    height: 64px !important;
    transition: 0.2s;
  }
  .user-header__item-menu {
    margin-right: 0px !important;
    transition: 0.2s;
  }
  .user-header__item-img{
    margin-left: 20px;
    margin-right: 12px !important;
  }
  .user-header__item-title {
    display: none;
    transition: 0.2s;
  }
  .header-account-menu {
    margin-right: 0px;
    transition: 0.2s;
  }

  .user-header__logo {
    padding-right: 25px !important;
    transition: 0.2s;
  }

  .user-header__item-logo {
    height: 25px !important;
    transition: 0.2s;
  }
}

@media screen and (max-width: 600px) {
  .user-header__container {
    height: 54px !important;
    transition: 0.2s;
  }
    .user-header__item-logo {
    height: 20px !important;
    transition: 0.2s;
  }
}
</style>
