import { render, staticRenderFns } from "./SocialsFooter.vue?vue&type=template&id=43c37490&scoped=true&"
import script from "./SocialsFooter.vue?vue&type=script&lang=js&"
export * from "./SocialsFooter.vue?vue&type=script&lang=js&"
import style0 from "./SocialsFooter.vue?vue&type=style&index=0&id=43c37490&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43c37490",
  null
  
)

export default component.exports