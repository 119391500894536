<template>
  <div :style="{'margin-top': $route.name === 'course-info' ? '0':'110px'}"
       class="footer-wrapper">
    <div class="container">
      <div v-if="windowWidth > 940" class="footer">

        <div class="footer__socials-wrapper">
          <socials-footer/>
        </div>


        <div class="footer__main-wrapper">
          <main-footer/>
        </div>

<!--        <div class="footer__courses-wrapper">-->
<!--          <courses-footer/>-->
<!--        </div>-->


<!--        <div class="footer__contacts-wrapper">-->
<!--          <contacts-footer/>-->
<!--          <a href="https://t.me/+oPKQSypNtWxhMGFi" target="_blank" class="footer-mobile__social-link">-->
<!--            <img src="@/assets/img/socials/icon-telegram.svg" alt="telegram">-->
<!--          </a>-->
<!--        </div>-->

      </div>
      <div v-else class="footer-mobile">

        <socials-footer/>

        <div class="footer-mobile__group">
          <main-footer/>
<!--          <contacts-footer/>-->
<!--          <a href="https://t.me/+oPKQSypNtWxhMGFi" target="_blank" class="footer-mobile__link">-->
<!--            <img src="@/assets/img/socials/icon-telegram.svg" alt="telegram">-->
<!--          </a>-->
        </div>

<!--        <courses-footer/>-->

<!--        <contacts-footer/>-->
      </div>
    </div>
  </div>
</template>

<script>
import SocialsFooter from "./components/SocialsFooter";
import MainFooter from "./components/MainFooter";
// import ContactsFooter from "./components/ContactsFooter";

export default {
  name: "AppFooter",
  components: {
    // ContactsFooter,
    MainFooter,
    SocialsFooter
  },

  computed: {
    windowWidth() {
      return this.$store.state.windowWidth
    },
  },

}
</script>

<style lang="scss">
.footer-wrapper {
  display: flex;
  margin-top: 110px;
  background: #222222;
  transition: 0.2s;
  z-index: 1;
}

.footer {
  display: flex;
  width: 100%;
  padding: 50px 0;
  box-sizing: border-box;
  transition: 0.2s;

  &__socials-wrapper {
    margin-right: 140px;
    transition: 0.2s;
  }

  &__main-wrapper {
    margin-right: 87px;
    transition: 0.2s;
  }

  &__courses-wrapper {
    margin-right: 60px;
    transition: 0.2s;
  }

  @media screen and (max-width: 1200px) {
    &__socials-wrapper {
      margin-right: 90px;
    }
    &__main-wrapper {
      margin-right: 60px;
    }
    &__courses-wrapper {
      margin-right: 40px;
    }
  }
  @media screen and (max-width: 980px) {
    &__socials-wrapper, &__main-wrapper, &__courses-wrapper {
      margin-right: 0;
    }

    padding: 50px 20px;
    justify-content: space-between;
  }
  @media screen and (max-width: 940px) {
    &__socials-wrapper, &__main-wrapper, &__courses-wrapper {
      margin-right: 0;
    }
    &__socials-wrapper {
      display: flex;
    }

    .footer-main__link:last-child {
      max-width: max-content;
    }
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 100px;
    row-gap: 50px;
    max-width: 700px;
    margin: 0 auto;
  }
  @media screen and (max-width: 740px) {
    column-gap: 60px;
    row-gap: 40px;
  }
  @media screen and (max-width: 650px) {
    column-gap: 40px;
    row-gap: 34px;
  }
  @media screen and (max-width: 530px) {
    display: flex;
    flex-direction: column;
    row-gap: 26px;
    width: max-content;
  }
  @media screen and (max-width: 440px) {
    padding: 40px 0;
    &__socials-wrapper {
      padding: 0 30px;

      .footer-socials > img {
        margin-bottom: 26px;
        width: 131px;
      }

      .footer-socials__link {
        width: 60px;
        height: 60px;
        border-radius: 14px;

        img {
          width: 28px;
        }
      }
    }

    &__main-wrapper {
      padding: 0 30px;
    }

    &__courses-wrapper {
      .footer-courses__title {
        padding: 0 10px;
      }

      padding: 0 20px;
    }

    &__contacts-wrapper {
      padding: 0 30px;
    }
  }
  @media screen and (max-width: 320px) {
    .footer-main__link:last-child {
      max-width: 200px;
    }
  }
}

.footer-mobile {
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  padding: 40px 30px;
  box-sizing: border-box;
  transition: 0.2s;

  &__social-link {
    margin-top: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 50px;
    width: 50px;

    background: #F1F5FE;
    border-radius: 18px;
  }

  &__group {
    display: flex;
    column-gap: 64px;
    margin-bottom: 30px;
    @media screen and (max-width: 700px) {
      column-gap: 58px;
    }
    @media screen and (max-width: 600px) {
      column-gap: 17px;
    }
    @media screen and (max-width: 540px) {
      .footer-contacts {
        display: none;
      }
    }

  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 70px;
    width: 70px;

    margin-left: auto;
    background: #F1F5FE;
    border-radius: 18px;

    @media screen and (max-width: 540px) {
      width: 50px;
      height: 50px;
    }
  }

  .footer-socials {

    img {
      margin-left: 0;
    }

    &__links {
      display: none;
    }
  }

  .footer-courses {

    &__description {
      max-width: 456px;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 20px;

      @media screen and (max-width: 360px) {
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 14px;
      }
      @media screen and (max-width: 320px) {
        max-width: 200px;
      }
    }
  }

  > .footer-contacts {
    display: none;
  }

  @media screen and (max-width: 540px) {
    .footer-socials {
      margin-bottom: 26px;

      &__links {
        display: flex;
      }
    }

    > .footer-contacts {
      display: block;
    }
    .footer-courses {
      margin-bottom: 26px;
    }
  }

  @media screen and (max-width: 360px) {
    padding: 40px 20px;

    &__group {
      margin-bottom: 26px;
    }
  }
}
</style>