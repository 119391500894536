<template>
  <div class="main-wrapper">
    <course-info-header v-if="$route.name === 'course-info' && !isLoggedIn"/>
    <user-header v-else-if="isLoggedIn"/>
    <app-header v-else/>
      <transition appear name="el-fade-in" mode="out-in">
        <div :class="{'container':$route.name !== 'home'}" :key="$route.name" v-if="init">
        <router-view/>
        </div>
        <div v-else class="main-wrapper__preloader"
             v-loading="true"
             element-loading-spinner="el-icon-loading"></div>
      </transition>
    <app-footer/>
  </div>
</template>

<script>
import AppFooter from "../../components/footer/AppFooter";
import UserHeader from "../../components/header/user/userHeader/UserHeader";
import AppHeader from "../../components/header/user/appHeader/AppHeader";
import CourseInfoHeader from "../../components/header/user/courseInfoHeader/CourseInfoHeader";
import {userService} from "./user.service";
import {coursesService} from "@/app/user/courses/courses.service";
import {myCoursesService} from "@/app/user/myCourses/myCourses.service";

export default {
  name: "MainWrapper",
  components:{
    AppFooter,
    UserHeader,
    AppHeader,
    CourseInfoHeader
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth
    },
    isLoggedIn() {
      return this.$store.state.user.isLoggedIn
    }
  },
  data() {
    return {
      init: false
    }
  },
  mounted() {
    this.initCourses()
  },
  methods: {
    async initCourses() {
      try {
        await coursesService.init()
        if (this.isLoggedIn) {
          userService.getUserInfo()
          userService.getAvatar()
         await myCoursesService.getMyCourses()
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.init = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .main-wrapper{
    display: flex;
    flex-direction: column;

    overflow: hidden;
    position: relative;

    .container {
      padding-top: 78px;
      min-height: calc(100vh - 200px);
      transition: 0.2s;
    }

    &__preloader {
      width: 100%;
      height: calc(100vh - 100px);
    }

    .course-info-header~.container {
      padding-top: 90px;
    }
  }

  @media screen and (max-width: 980px) {
    .container {
      padding-top: 68px !important;
      transition: 0.2s;
    }
  }
  @media screen and (max-width: 600px) {
    .container {
      padding-top: 58px !important;
      transition: 0.2s;
    }
  }
</style>

<style lang="scss">
.main-wrapper__preloader {
  .el-loading-mask {
    .el-icon-loading {
      font-size: 26px;
      color: #1454F2;
    }
  }
}
</style>
