<template>
  <div class="footer-socials">
    <img src="@/assets/img/socials/logo-icon.svg" alt="telegram">

    <div class="footer-socials__links">
<!--      <a href="https://t.me/+oPKQSypNtWxhMGFi" target="_blank" class="footer-socials__link">-->
<!--        <img src="@/assets/img/socials/icon-telegram.svg" alt="telegram">-->
<!--      </a>-->
<!--      <a href="https://instagram.com/o_mybrain?utm_medium=copy_link" target="_blank" class="footer-socials__link">-->
<!--        <img src="@/assets/img/socials/instagram-icon.png" alt="instagram">-->
<!--      </a>-->
    </div>

  </div>
</template>

<script>
export default {
  name: "Socials"
}
</script>

<style lang="scss" scoped>
.footer-socials {

  &__links {
    display: flex;
    align-items: center;
    //justify-content: center;
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 70px;
    width: 70px;

    background: #F1F5FE;
    border-radius: 18px;

    &:last-child {
      background: #FFF6F4;
    }
  }
  > img {
    display: block;
    margin: -5px auto 30px auto;

    transition: 0.2s;
  }

  @media screen and (max-width: 360px) {
   > img {
     width: 131px;
     margin: 0 auto 26px auto;
   }

    padding: 0 10px;
    box-sizing: border-box;

  }
}
</style>