<template>
  <div class="footer-main">
    <div class="footer-main__title">Основное</div>
    <router-link to="/" class="footer-main__link">Главная</router-link>
    <router-link to="/courses" class="footer-main__link">Наши курсы</router-link>
    <a href="/polpd.pdf" target="_blank" class="footer-main__link">Политика конфиденциальности</a>
  </div>
</template>

<script>
export default {
  name: "MainFooter"
}
</script>

<style lang="scss" scoped>
.footer-main {
  display: flex;
  flex-direction: column;

  &__title {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;


    color: #FFFFFF;

    margin-bottom: 29px;
    transition: 0.2s;

    @media screen and (max-width: 940px) {
      font-size: 22px;
      margin-bottom: 16px;
    }
  }

  &__link {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    color: #FFFFFF;
    text-decoration: none;

    margin-bottom: 16px;

    &:last-child {
      max-width: 180px;
      margin-bottom: 0;
    }

    @media screen and (max-width: 940px) {
      font-weight: 500;
    }
  }

  @media screen and (max-width: 360px) {
    &__title {
      font-size: 20px;
      margin-bottom: 14px;
    }
    &__link {
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 14px;
    }

    padding: 0 10px;
    box-sizing: border-box;

  }
}
</style>