<template>
  <div class="user-logo-wrapper">
    <div v-if="!userAvatar" class="user-logo">
      <img class="user-logo__default-avatar" src="@/assets/img/user/user-no-photo-header.svg" alt="user">
    </div>
    <span v-else class="user-logo">
      <img class="user-logo__img" :src="userAvatar" alt="user">
    </span>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: 'Avatar',
  data() {
    return {
      avatar: null,
    }
  },
  computed: {
    ...mapGetters(['userAvatar'])
  },
  mounted() {
    this.avatar = this.userAvatar
  },
  methods: {
    ...mapMutations(['setUserAvatar'])
  }
}
</script>

<style scoped lang="scss">
@import "../../../../../assets/scss/variables";

.user-logo {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;

  &__avatar {
    width: 100%;
    height: 100%;
  }

  &__default-avatar {
    width: 32px;
    height: 32px;
  }

  &__img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
  }

}

@media screen and (max-width: 980px) {
  .user-logo {
    margin-right: 24px !important;
    transition: 0.2s;
  }
}

</style>
