<template>
  <div class="burger-menu-wrapper"
       @click="menuActive = !menuActive">
    <div class="burger-menu-wrapper__menu"/>
    <div class="burger-menu-wrapper__menu-items"/>

    <el-drawer title="Наши направления"
               custom-class="burger-menu-wrapper__item-drawer"
               :visible="menuActive"
               direction="ttb">

      <categories/>
      <el-divider/>
    </el-drawer>

  </div>
</template>

<script>
import Categories from "./CategoriesForMenu";

export default {
  name: "BurgerMenu",
  components: {
    Categories
  },
  data() {
    return {
      menuActive: false
    }
  },
}
</script>

<style lang="scss">
.burger-menu-wrapper {
  display: flex;
  align-items: center;

  height: 17px;
  width: 32px;

  margin-right: 18px;

  position: relative;
  cursor: pointer;
  transition: 0.1s;

  &__menu-items {
    &:after {
      content: '';
      position: absolute;
      top: 4px;
      left: 7px;

      background: #2A2A2A;

      width: 16px;
      height: 1px;
      transition: 0.2s;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 4px;
      left: 7px;

      background: #2A2A2A;

      width: 16px;
      height: 1px;
      transition: 0.2s;
    }
  }

  &__menu {
    position: absolute;
    left: 7px;

    background: #2A2A2A;

    width: 16px;
    height: 1px;
  }

  &__item-drawer {
    height: 230px !important;
    cursor: default;
  }

  .el-divider {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
  }

  .el-drawer__wrapper {
    top: 74px;

    .el-drawer__close-btn {
      display: none;
    }

    .el-drawer__header {
      font-family: Raleway;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;

      color: #121212;

      width: 100%;
      max-width: 1170px;
      margin: 12px auto 24px auto;

      padding: 0px;
    }

    .el-drawer__body {
      width: 100%;
      max-width: 1170px;
      margin: 0 auto;

      &::-webkit-scrollbar {
        width: 8px;
        margin-right: 20px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        margin-bottom: 20px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #D3D3D3;
        box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.v-modal {
  z-index: 1 !important;
}


@media screen and (max-width: 1200px) {
  .burger-menu-wrapper .el-drawer__header {
    max-width: 980px !important;
    transition: 0.2s;
  }
  .burger-menu-wrapper .el-drawer__body {
    max-width: 980px !important;
    transition: 0.2s;
  }
}

@media screen and (max-width: 980px) {
  .burger-menu-wrapper {
    margin-left: 24px;
    transition: 0.2s;

    .el-drawer__wrapper {
      top: 64px;
      transition: 0.2s;
    }
  }
  .burger-menu-wrapper .el-drawer__header {
    padding-left: 24px !important;
    transition: 0.2s;
  }
}

@media screen and (max-width: 600px) {
  .burger-menu-wrapper {
    .el-drawer__wrapper {
      top: 54px;
      transition: 0.2s;
    }
  }
}


</style>