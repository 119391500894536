<template>
  <div class="account">
    <div class="account-tabs account__tools-tabs">
      <div class="top">
        <div class="account-tabs__item-name">{{ $store.state.user.info.fullName }}</div>
        <div class="account-tabs__item-email">{{ $store.state.user.info.email }}</div>
      </div>
      <div class="middle">
        <router-link to="/profile" class="account-tabs__item">
          <div class="account-tabs__item-text">Профиль</div>
        </router-link>
        <router-link to="/settings" class="account-tabs__item">
          <div class="account-tabs__item-text">Настройки</div>
        </router-link>

        <div class="account__courses">
          <el-divider/>
          <router-link to="/my-courses" class="account-tabs__item">
            <div class="account-tabs__item-text">Мои курсы</div>
          </router-link>
          <router-link to="/courses" class="account-tabs__item">
            <div class="account-tabs__item-text">Все курсы</div>
          </router-link>
        </div>
      </div>

      <div class="bottom">
        <div @click="logout" class="account-tabs__item-text-exit" style="color: #FF0000;">Выйти</div>
      </div>
    </div>
  </div>
</template>

<script>
import {authService} from "../../../../../app/user/auth/auth.service";

export default {
  name: 'Account',
  methods: {
    logout: function () {
      authService.logout()
     // this.$router.push('/')
    },
  }
}
</script>

<style scoped lang="scss">
.middle {
  margin-bottom: 30px;
}

.account {
  padding: 10px;

  &__courses{
    display: none;

    .el-divider {
      margin: 15px 0;
    }

    @media screen and (max-width: 980px) {
      display: block;
    }
  }


  &-tabs {
    &__item {
      display: flex;
      margin-bottom: 12px;
      width: max-content;
      text-decoration: none;

      &-text {
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;

        color: #121212;
      }

      &-text-exit {
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;

        color: #FF0000;
        cursor: pointer;
      }

      &-name {
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        word-break: break-all;

        color: #121212;

        margin-bottom: 8px;
      }

      &-email {
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;

        color: #121212;

        margin-bottom: 30px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__tools-tabs {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
</style>
